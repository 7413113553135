<script>
import {mapGetters} from "vuex";

export default {
    name: 'additional-version',

    props: {
        modelValue: {},
        field: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        focused: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue'],

    computed: {
        ...mapGetters({
            fieldVersions: 'fieldVersion/all'
        }),

        model: {
            get: function () {
                return JSON.parse(JSON.stringify(this.modelValue || {}));
            },

            set: function (value) {
                this.$emit('update:modelValue', value)
            },
        }
    },

    methods: {
        updateName: function () {
            this.$emit('update:modelValue', this.model)
        },

        updateDate: function (event) {
            Date.prototype.formattedDate = function () {
                const pad = (number) => {
                    return number < 10 ? '0' + String(number) : number
                }

                return [this.getFullYear(), pad(this.getMonth()+1), pad(this.getDate())].join('-')
                    + ' ' +
                    [pad(this.getHours()), pad(this.getMinutes()), pad(this.getSeconds())].join(':');

            };
            const value = new Date(event.target.value);
            this.model.date = value.formattedDate();
            this.$emit('update:modelValue', this.model);
        },
    },

    mounted() {
        if (this.focused) {
            this.$refs['input'].focus();
        }
    }
}
</script>

<template>
    <div class="col-12 col-sm-6 col-md-3 col-lg-2">
        <label
            :for="field.transformed_name + '_version_' + $.uid"
            class="form-label"
        >{{ $t('dynamic_fields::field_versions.columns.name').ucFirst() }}</label>
        <input
            ref="input"
            :id="field.transformed_name + '_version_' + $.uid"
            class="form-control"
            list="versions"
            :placeholder="$t('dynamic_fields::field_versions.placeholders.name').ucFirst()"
            v-model="model.name"
            @change="updateName()"
            :disabled="disabled"
        />
        <datalist id="versions">
            <option v-for="(fieldVersion, index) in fieldVersions" :key="index" :value="fieldVersion.translation_key ? $t(fieldVersion.translation_key).ucFirst() : (fieldVersion.name)">{{ fieldVersion.translation_key ? $t(fieldVersion.translation_key).ucFirst() : (fieldVersion.name) }}</option>
        </datalist>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-lg-2">
        <label
            :for="field.transformed_name + '_date_' + $.uid"
            class="form-label"
        >{{ $t('dynamic_fields::field_versions.columns.date').ucFirst() }}</label>
        <input
            :id="field.transformed_name + '_date_' + $.uid"
            type="datetime-local"
            class="form-control"
            v-model="model.date"
            @change="updateDate($event)"
            :disabled="disabled"
        />
    </div>
</template>

<style scoped>

</style>
